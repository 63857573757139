import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  getunileveldata,
  updateunileveldata,
} from "backendServices/ApiCalls";
import {
  CircularProgress,
  Grid,
  List,
} from "@mui/material";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import Div from "@jumbo/shared/Div";
import { Form, Formik } from "formik";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "auto",
};

const validationSchema = yup.object({
  // ranks_name: yup.string().required("Rank Name is required"),
  percentage: yup.number().integer().required("Percentage is required"),
});

function ManageUnilevel() {
  const [unilevelData, setUnilevelData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = useState(null);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const GetUnilevelData = () => {
    getunileveldata(
      (response) => {
        setUnilevelData(response?.data?.data);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  const handleUpdate = (data, setSubmitting, resetForm) => {
    const additionalData = {
      id: rowData.id,
    };
    const newData = { ...data, ...additionalData };
    updateunileveldata(
      newData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setTimeout(() => {
            setSubmitting(false);
          }, 2000);
          GetUnilevelData();
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetUnilevelData();
  }, []);

  const columns = [
    {
      field: "id",
      headerName: "Sr #",
      width: 150,
      editable: true,
    },
    {
      field: "name",
      headerName: "Level",
      width: 250,
      editable: true,
    },
    {
      field: "percentage",
      headerName: "Percentage",
      width: 250,
      editable: true,
      groupable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <>
          <EditIcon
            style={{ cursor: "pointer", color: "green" }}
            onClick={() => handleOpen(params?.row?.id)}
          />
        </>
      ),
    },
  ];
  const handleOpen = (id) => {
    const rowToEdit = unilevelData.find((row) => row.id === id);
    setRowData(rowToEdit);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#fff",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <Grid container fullWidth p={2} alignItems="center" justifyContent="center">
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          title={"Manage Unilevel"}
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          {/* {isLoading ? (
              <Div
              sx={{
                mt:"20%",
                ml:"45%",
                mb: "20%"
              }}
            >
              <CircularProgress />
            </Div>
          ):( */}

          <Box sx={{ height: 400, width: 1 }} textAlign="right">
            {({ isSubmitting }) => (
              <Div
                sx={{
                  mt: "20%",
                  ml: "45%",
                  mb: "20%",
                }}
              >
                <CircularProgress />
              </Div>
            )}
            <DataGrid
              initialState={{
                pagination: { paginationModel: { pageSize: 6 } },
              }}
              rows={unilevelData}
              getRowId={(row) => row.id}
              columns={columns}
              slots={{ toolbar: GridToolbar }}
              sx={gridDesign}
              pageSizeOptions={[6, 12, 18, 24, 30]}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </Box>
          {/* )} */}
          {/* Update Product Modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Div sx={style}>
              <Typography id="modal-modal-title" variant="h3" component="h2">
                Update Unilevel Percentage
              </Typography>
              <List disablePadding sx={{ mb: 2, mt: 5 }}>
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    // ranks_name: rowData?.ranks_name || "",
                    percentage: rowData?.percentage || "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(data, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    handleUpdate(data, setSubmitting, resetForm);
                    handleClose();
                  }}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form
                      style={{ textAlign: "left" }}
                      noValidate
                      autoComplete="off"
                    >
                      {/* <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                        <JumboTextField
                          fullWidth
                          name="ranks_name"
                          label="Rank Name"
                          type="text"
													inputProps={{
														readOnly: true
													}}
                        />
                      </Div> */}
                      <Div sx={{ mt: 1, mb: 3, pl: 2, pr: 2 }}>
                        <JumboTextField
                          fullWidth
                          name="percentage"
                          label="Percentage"
                          type="number"
                        />
                      </Div>
                      <Div sx={{ mt: 1, pl: 2, pr: 2 }}>
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          Submit
                        </LoadingButton>
                      </Div>
                    </Form>
                  )}
                </Formik>
              </List>
            </Div>
          </Modal>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
}

export default ManageUnilevel;
