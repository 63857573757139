import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { postRequest } from "backendServices/ApiCalls";
import { Chip, IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { format } from "date-fns";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

const initialState = {
  initialState: {
    columns: {
      columnVisibilityModel: {
        id: false,
        avatar: false,
        website: false,
        email: false,
        phone: false,
        username: false,
        city: false,
        company: false,
        position: false,
        lastUpdated: false,
        salary: false,
      },
    },
  },
};

const ApprovedPackages = () => {
  const [payoutdata, setPayoutData] = useState([]);
  const [imagUrl, setImageUrl] = useState();

  const PayoutData = () => {
    postRequest(
      "/getpackages",
      { status: "approved" },
      (response) => {
        if (response?.data?.status === "success") {
          setPayoutData(response?.data?.data);
          setImageUrl(response?.data?.picUrl);
        }
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };
  console.log("payoutdata", payoutdata);

  useEffect(() => {
    PayoutData();
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "User",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "title",
      headerName: "Package",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 120,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return `$${params.value}`;
      },
    },
    {
      field: "paymentmethod",
      headerName: "Payment Method",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "walletaddress",
      headerName: "Wallet Address",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: false,
      groupable: false,
      aggregable: false,
    },
    {
      field: "picture",
      headerName: "Image",
      width: 200,
      renderCell: (params) => (
        <img
          src={`${imagUrl}${params.row.paymentproof}`} // Assuming your image URLs are constructed like this
          alt={`Product ${params.row.title}`}
          style={{ width: "50%", height: "auto" }}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: false,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return params.value === "pending" ? (
          <Chip color="warning" label="Pending" />
        ) : params.value === "rejected" ? (
          <Chip color="error" size="small" label="Rejected" />
        ) : (
          <Chip color="success" size="small" label="Approved" />
        );
      },
    },
    {
      field: "createdat",
      headerName: "Requested On",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => {
        return format(new Date(params.value), "dd-MM-yyyy");
      },
    },
  ];

  const rows = payoutdata;
  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
        color: "#f5343e",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  return (
    <JumboDemoCard
      title={"Approved Packages History"}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 400, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default ApprovedPackages;
