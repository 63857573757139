import { Box, Button, Chip, CircularProgress, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {postRequest } from 'backendServices/ApiCalls';
import React, { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material';
import Div from '@jumbo/shared/Div';
import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
        '& .MuiButton-text': {
            fontSize: '13px !important',
            color: '#f5343e',
        },
        '& .MuiBadge-badge': {
            backgroundColor: '#074682',
        },
        '& .MuiInput-root': {
            borderRadius: 2,
            paddingLeft: 2,
            overflow: 'hidden',
        },
    },
};




const OrderHistory = () => {

    const [orderData, setOrderData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [alertopen, setAlertOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [selectedorderdata, setSelectedOrderData] = useState([]);
    const [imageurl, setImageUrl] = useState(null);
    const [loadingStates, setLoadingStates] = useState({});
    const [copiedRows, setCopiedRows] = useState([]);
    const [loading, setLoading] = useState(false);

    const OrderData = () => {
      setLoading(true)
        postRequest('/getorderhistory','',(response) => {
          if (response?.data?.status === "success") { 
                setOrderData(response?.data?.data)
                setLoading(false)
            }
          }, (error) => {
            console.log(error?.response?.data);
            setLoading(false)
          })
    }

    useEffect(() => {
        OrderData();
    }, []);


    const handleOrderDetails = (id) => {
        setLoadingStates((prevState) => ({ ...prevState, [id]: true }));
        setTimeout(() => {
        let params = {
            orderid: id
        }
        postRequest(
            '/getorderdetails',
            params,
            (response) => {
                if (response?.data?.status === 'success') {
                    setSelectedOrderData(response?.data?.data);
                    setOpen(true)
                }
                setLoadingStates((prevState) => ({ ...prevState, [id]: false }));
            },
            (error) => {
                console.log(error?.response?.data);
            }
            
        );
    }, 2000); // Simulate a 2-second delay, replace with your actual API call
};

const CopyToClipboardCell = ({ value }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
  };

  return (
    <div>
      <Tooltip title={isCopied ? "Copied" : "Copy to Clipboard"} arrow>
        <IconButton onClick={copyToClipboard} disabled={isCopied}>
          {isCopied ? <CheckCircleIcon color='success' /> : <ContentCopyIcon />}
        </IconButton>
      </Tooltip>

    </div>
  );
};
    const columns = [

        {
            field: 'id',
            headerName: 'Sr #',
            width: 50,
        },
        {
            field: 'orderid',
            headerName: 'Order ID',
            width: 150,
        },
        {
            field: 'aa',
            headerName: 'Order Details',
            renderCell: (params) => (
                <div>
                    {/* Button to open the modal */}
                    <Button variant="outlined" disabled={loadingStates[params.row.orderid]} size='small' color="primary" onClick={() => handleOrderDetails(params.row.orderid)}>
                    {loadingStates[params.row.orderid] ? (
              <CircularProgress size={24} />
            ) : (
              'Details'
            )}
                    </Button>
                    
                </div>
            ),
            width: 150,
        },

        {
            field: 'status',
            headerName: 'Shipping Status',
            width: 150,
            renderCell : (params) => {
              return  params.value === 'pending' ? (<Chip color='warning' label='Pending' />)
               :
               params.value === 'rejected' ? (<Chip color='error' label='Rejected' />)
               :
               (<Chip color='success' label='Approved' />)
         
             }
        },
        {
            field: 'paymentstatus',
            headerName: 'Payment Status',
            width: 150,
        },

        {
          field: "storename",
          headerName: "Stockist",  
          dataGeneratorUniquenessEnabled: true,
          width: 150,
          editable: false,
          groupable: false,
          aggregable: false, 
  
      },
      {
        field: "address",
        headerName: "Stockist Address",  
        dataGeneratorUniquenessEnabled: true,
        width: 200,
        editable: false,
        groupable: false,
        aggregable: false, 
        renderCell: (params) => (
          <>
            <CopyToClipboardCell value={params.value} />
            {params.value}
          </>
        ),
    },
    {
      field: "mobile",
      headerName: "Stockist Contact #",  
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: false,
      groupable: false,
      aggregable: false,    
  }, 


    ];
    
    if(loading){
      return  <Div
      sx={{
          display: 'flex',
          minWidth: 0,
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
      }}
    >
      <CircularProgress sx={{m: '-40px auto 0'}}/>
    </Div>
    }

    console.log('ffffffffff', orderData)
    return (
      <JumboDemoCard
      title={"Order History"}
      wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
  >

            <Box sx={{ height: 400, width:1 }}>
                <DataGrid
                    initialState={{
                        initialState: {
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                    avatar: false,
                                    website: false,
                                    email: false,
                                    phone: false,
                                    username: false,
                                    city: false,
                                    company: false,
                                    position: false,
                                    lastUpdated: false,
                                    salary: false,
                                },
                            },
                        },
                        pagination: { paginationModel: { pageSize: 6 } },
                    }}
                    rows={orderData}
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    sx={gridDesign}
                    pageSizeOptions={[6, 12, 18, 24, 30]}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Box>



            {/* Modal */}
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ minWidth: 350 }}
            >
                <Div sx={style}>
                    <Typography id="modal-modal-title" variant="h4" 
                        component="h2"
                        display='flex' 
                        justifyContent='space-between'>
                        Order Details
                        <Close style={{cursor: 'pointer'}}  onClick={handleClose}/>
                    </Typography>
                    
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <TableContainer component={Paper} sx={{ overflowY : 'scroll', height: "auto"}}>
                    <Table sx={{ minWidth: 350  }}>
                      <TableHead style={{ fontWeight: 'bolder' }}>
                        <TableRow >
                          <TableCell>Product Image</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Quantity</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {selectedorderdata.map((item) => (
                          <>
                            <TableRow key={item.id}>
                              <TableCell>
                                <img src={`${imageurl}${item.picture}`} alt={item.title} height="40"
                                  style={{ borderRadius: "50%", width: '40px' }} />

                              </TableCell>
                              <TableCell > {item.title}</TableCell>
                              <TableCell>N{item.price}</TableCell>
                              <TableCell>{item.quantity}</TableCell>
                            </TableRow>

                          
                       

                        
                        </>
                        
                        ))}
                        <TableCell
                        align="right" colSpan={4}>
                        
                             Subtotal: N{selectedorderdata.reduce((total, item) => total + item.price * item.quantity, 0)}
                        </TableCell>
                      </TableBody>
                      
                    </Table>
                  </TableContainer>
                    </Typography>
                </Div>
            </Modal>
            

            
</JumboDemoCard>
    )

}

export default OrderHistory
